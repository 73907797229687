import React from "react"
import { UniversalLink } from "../components/UniversalLink"

interface Point {
  date: string
  name: string
  percent: number
}

const wave6: Point[] = [
  {
    date: ``,
    name: ``,
    percent: 0,
  },
  {
    date: `April 25`,
    name: `Application Opens`,
    percent: 2.31,
  },
  {
    date: `June 25`,
    name: `Application Closes`,
    percent: 24.23,
  },
  {
    date: `July`,
    name: `Applications Reviewed`,
    percent: 46.15,
  },
  {
    date: `August`,
    name: `Finalist Interviews`,
    percent: 68.07,
  },
  {
    date: `September`,
    name: `Awardees Selected&nbsp;and Announced`,
    percent: 90,
  },
]

const wave7: Point[] = [
  {
    date: ``,
    name: ``,
    percent: 0,
  },
  {
    date: `August 31st`,
    name: `Application Opens`,
    percent: 2.31,
  },
  {
    date: `October 1st`,
    name: `Application Closes`,
    percent: 24.23,
  },
  {
    date: `October - December`,
    name: `Applications Reviewed`,
    percent: 46.15,
  },
  {
    date: `January 2024`,
    name: `Finalist Interviews`,
    percent: 68.07,
  },
  {
    date: `February 2024`,
    name: `Awardees Selected&nbsp;and Announced`,
    percent: 90,
  },
]
const waveRolling: Point[] = [
  {
    date: ``,
    name: ``,
    percent: 0,
  },
  {
    date: ``,
    name: `Eligibility and Interest Form`,
    percent: 20,
  },
  {
    date: ``,
    name: `Full Application`,
    percent: 40,
  },
  {
    date: ``,
    name: `Finalist Interviews`,
    percent: 60,
  },
  {
    date: ``,
    name: `Awarding`,
    percent: 80,
  },
]

export interface Waves {
  name: string
  description: string | JSX.Element
  extraInfo?: string
  wave: Point[]
}

export const waves: Waves[] = [
  // {
  //   name: `6`,
  //   description: `Web3 and Finance`,
  //   wave: wave6,
  // },
  // {
  //   name: `7`,
  //   description: (
  //     <>
  //       Decentralized Exchange (DEX) and Financial Inclusion, learn more
  //       {` `}
  //       <UniversalLink to="https://dev.to/ripplexdev/xrpl-grants-wave-7-igniting-innovation-in-dex-and-financial-inclusion-3npo">
  //         here
  //       </UniversalLink>
  //       !
  //     </>
  //   ),
  //   wave: wave7,
  // },
  {
    name: ``,
    description: ``,
    wave: waveRolling,
  },
]
