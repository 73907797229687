import React, { useRef, useState, useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { clsx } from "clsx"
import type { Waves } from "../../data/timelineData"
import rightArrow from "../../images/right-arrow.svg"
import leftArrow from "../../images/left-arrow.svg"
import "./timeline.scss"

interface TimelineProps {
  waves: Waves[]
}

type Props = {
  children: React.ReactElement
  waitBeforeShow?: number
}

const Delayed = ({ children, waitBeforeShow = 500 }: Props) => {
  const [isShown, setIsShown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true)
    }, waitBeforeShow)
    return () => clearTimeout(timer)
  }, [waitBeforeShow])

  return isShown ? children : null
}

export const Timeline: React.FC<TimelineProps> = (props) => {
  const [currentWaveIdx, setIdx] = useState(props.waves.length - 1)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const hasNextWave = () => currentWaveIdx < props.waves.length - 1

  const hasPrevWave = () => currentWaveIdx > 0

  const nextWave = () => {
    if (hasNextWave()) {
      setIdx(currentWaveIdx + 1)
    }
  }

  const prevWave = () => {
    if (hasPrevWave()) {
      setIdx(currentWaveIdx - 1)
    }
  }

  const currentWave = props.waves[currentWaveIdx]

  let list = `${currentWave.wave[1].percent}% `
  for (let i = 2; i < currentWave.wave.length; i++) {
    list += `calc(${currentWave.wave[i].percent}% - ${
      currentWave.wave[i - 1].percent
    }%) `
  }
  list += `calc(100% - ${
    currentWave.wave[currentWave.wave.length - 1].percent
  }%)`

  const nextDiv = (
    <div className="next">
      <button className="btn btn-link" onClick={() => nextWave()}>
        <span className="text caption-3">NEXT WAVE</span>
        <span className="arrow">
          <img src={rightArrow} alt="right-arrow" />
        </span>
      </button>
    </div>
  )

  const prevDiv = (
    <div className="prev">
      <button className="btn btn-link" onClick={() => prevWave()}>
        <span className="arrow">
          <img src={leftArrow} alt="left-arrow" />
        </span>
        <span className="text caption-3">PREV WAVE</span>
      </button>
    </div>
  )

  let waveToggle = (
    <div className="wave-move">
      {nextDiv}
      {prevDiv}
    </div>
  )

  if (!hasNextWave()) {
    waveToggle = <div className="wave-move">{prevDiv}</div>
  }

  if (!hasPrevWave()) {
    waveToggle = <div className="wave-move">{nextDiv}</div>
  }

  if (!hasNextWave() && !hasPrevWave()) {
    waveToggle = <div className="wave-move" />
  }

  return (
    <div className="timeline" key={currentWaveIdx}>
      <div ref={ref} className={clsx(`bar`, inView && `inview`)}>
        <div className="gradient" />
        <div
          className="list"
          style={{
            gridTemplateColumns: `${list}`,
            gridTemplateRows: `${list}`,
          }}
        >
          {currentWave.wave.map((point, index) => (
            <Delayed
              key={point.date}
              waitBeforeShow={inView ? 18 * (point.percent - 1) : 100000000}
            >
              <div className="point">
                <div className="line" />
                <div className="text">
                  <div
                    className="caption-3"
                    dangerouslySetInnerHTML={{ __html: point.date }}
                  />
                  <p dangerouslySetInnerHTML={{ __html: point.name }} />
                </div>
              </div>
            </Delayed>
          ))}
        </div>
      </div>
    </div>
  )
}
