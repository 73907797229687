import "./carousel.scss"
import clsx from "clsx"
import React, { useEffect } from "react"
import { UniversalLink } from "../UniversalLink"
import { type Grantee } from "./grantee"
import { GranteeCard } from "./GranteeCard"

interface GranteeCarouselProps {
  grantees: Grantee[]
}

// This is arbitrary, but it's good to cap it even if we mark a bunch as featurable
const DISPLAYED_GRANTEES = 6

const SM = 576
const MD = 768

// Track if we have added a listener for screen size changes yet
let listenerAdded = false

export const GranteeCarousel: React.FC<GranteeCarouselProps> = ({
  grantees,
}) => {
  // Featured grantees should have a logo and tags
  const granteeCopy = grantees.filter((grantee) => grantee.feature)
  // Alphabatize
  const shuffledGrantees = granteeCopy.sort((a, b) => {
    if (a.name == b.name) {
      return 0
    } else if (a.name > b.name) {
      return 1
    } else {
      return -1
    }
  })
  const featuredGrantees = shuffledGrantees.slice(0, DISPLAYED_GRANTEES)

  // Adds a listener to update the display of carousel cards if screen changes
  // Only adds the listener once. Depends on a global `listenerAdded` variable.
  useEffect(function tryToAddWindowChangeListener() {
    const shouldAddListener = typeof window !== `undefined` && !listenerAdded
    const eventListener = () => {
      updateCarousel()
    }
    if (shouldAddListener) {
      window.addEventListener(`resize`, eventListener)
      listenerAdded = true
    }
    // Cleanup
    return () => {
      if (shouldAddListener) {
        window.removeEventListener(`resize`, eventListener)
      }
    }
  })

  return (
    <div className="featured-grantees" suppressHydrationWarning>
      <h2 className="mb-[40px]">Featured Grantees</h2>
      <div className="carousel-container">
        <div className="carousel-container-inner">
          {featuredGrantees.map((grantee, index) => (
            <div
              className={clsx(`carousel-slide`, index)}
              key={grantee.name + grantee.wave + String(index)}
              onFocus={() => onFocusHandler(index)}
            >
              <GranteeCard
                grantee={grantee}
                useFirstChipColor={true}
                simplifiedView={true}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="carousel-nav-section flex flex-row flex-wrap items-center gap-y-[24px] justify-between">
        <div className="carousel-nav-buttons">
          <button
            className="carousel-nav carousel-nav-left drop-shadow-md"
            onClick={() => prev()}
          >
            <div className="carousel-button-arrow carousel-left-arrow" />
          </button>
          <button
            className="carousel-nav carousel-nav-right drop-shadow-md"
            onClick={() => next()}
          >
            <div className="carousel-button-arrow carousel-right-arrow" />
          </button>
        </div>
        <UniversalLink
          className="btn btn-primary btn-auto button-view-all align-middle"
          to="/grantees"
        >
          View All Grantees
        </UniversalLink>
      </div>
    </div>
  )
}

let selectedSlideIndex = 0

function onFocusHandler(slideIndex: number): void {
  selectedSlideIndex = Math.min(
    slideIndex,
    DISPLAYED_GRANTEES - getVisibleSlideCount()
  )
  updateCarousel()
}

function prev() {
  if (selectedSlideIndex <= 0) {
    return
  }
  selectedSlideIndex--
  updateCarousel()
}

function next() {
  if (
    selectedSlideIndex >=
    document.querySelectorAll(`.carousel-slide`).length - getVisibleSlideCount()
  ) {
    return
  }

  selectedSlideIndex++
  updateCarousel()
}

function getVisibleSlideCount(): number {
  return window.screen.width < 576 ? 1 : 2
}

function updateCarousel() {
  let offsetWidth = ``

  if (window.screen.width < SM) {
    offsetWidth = `100% + 40px`
  } else if (window.screen.width < MD) {
    offsetWidth = `50% + 20px`
  } else {
    offsetWidth = `40% + 40px`
  }

  const element = document.querySelector(
    `.carousel-container-inner`
  ) as HTMLElement

  if (element) {
    element.style.left = `calc((${offsetWidth}) * -${selectedSlideIndex})`
  }
}
